<template>
  <v-app>
    <PcCallBack></PcCallBack>
  </v-app>
</template>

<script>
import PcCallBack from "./component/PcCallBack";

export default {
  name: "login",
  components: {
    PcCallBack
  }
};
</script>
